<template>
    <div class="app-container">
        <v-runtime-template :template="templateContent"></v-runtime-template>
    </div>
</template>

<script>
    import VRuntimeTemplate from "v-runtime-template";

    export default {
        name: 'previewDetails',
        components: {
            VRuntimeTemplate,
        },
        props: {
            // 模版内容
            template: {
                type: String,
                required: true
            },
        },
        data(){
            return{
                templateContent :null,
            }
        },
        computed: {
            currentTemplate() {
                return this.template;
            },
        },
        watch: {
            currentTemplate: function () {
                this.initTemplate();
            }
        },
        created(){
            this.initTemplate();
        },
        methods:{
            //初始化模版
            initTemplate(){
                this.templateContent = this.template;
            },
            //构造一个仪表盘
            buildDashboardOptions(pointName,symbol,value){
                return {
                    tooltip : {
                        formatter: "{a} <br/>{b} : {c}V"
                    },
                    series: [
                        {
                            name: pointName,
                            type: 'gauge',
                            center: ['60%', '50%'],
                            min: 0,
                            max: 300,
                            splitNumber: 4,
                            radius: '80%',
                            axisLine: { // 坐标轴线
                                lineStyle: { // 属性lineStyle控制线条样式
                                    color: [
                                        [0.2, '#e4ac55'],
                                        [0.8, '#42c68c'],
                                        [1, '#e35151']
                                    ],
                                    width: 10,
                                    shadowColor: '#fff', //默认透明
                                    shadowBlur: 10
                                }
                            },
                            axisLabel: { // 坐标轴小标记
                                textStyle: {
                                    color: '#666',
                                    fontSize:10,
                                    shadowColor: '#fff', //默认透明
                                    shadowBlur: 10
                                }
                            },
                            splitLine: { // 分隔线
                                length:8, // 属性length控制线长
                                lineStyle: {
                                    width: 1,
                                    color: '#fff',
                                    shadowColor: '#fff', //默认透明
                                    shadowBlur: 10
                                }
                            },
//					指针
                            pointer: {
                                width: 2,
                                shadowColor: '#e7a954', //默认透明
                                shadowBlur: 5
                            },
                            detail: {
                                fontSize:14,
                                color:"#333",
                                offsetCenter:[0,'90%'],
                                formatter:'{value}'+symbol
                            },
//					数据
                            data: [{
                                value: value,
                                name: symbol
                            }]
                        }
                    ]
                };
            },
        }
    }
</script>

<style scoped>
</style>
