import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 查询设备类型对应的详情模版
 * @param params
 */
export function findDeviceDetailsTemplateByDeviceType(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/devicedetailstemplate/find',
        method: 'get',
        params
    })
}

/**
 * 保存设备类型对应的详情模版
 * @param params
 */
export function saveDeviceDetailsTemplate(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/devicedetailstemplate/save',
        method: 'post',
        data: params
    })
}
