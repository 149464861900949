<template>
    <div class="app-container" style="padding:10px;">
        <el-row>
            <el-col :span="3">
                <el-select v-model="form.deviceType" filterable clearable class="device-type" @change="getDeviceDetailsTemplateByDeviceType">
                    <el-option
                            v-for="item in deviceTypeList"
                            :key="item.itemCode"
                            :value="item.itemCode"
                            :label="item.itemName">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="20" :offset="1">
                <el-button type="primary" icon="el-icon-video-play" size="small" @click="runTemplate()">运行</el-button>
                <el-button type="primary" icon="el-icon-view" size="small" @click="previewTemplate()">预览</el-button>
                <el-button type="success" icon="el-icon-finished" @click="saveMapDetailsTemplate()" size="small">{{$t('commons.save') }}</el-button>
            </el-col>
        </el-row>
        <div class="el-row" style="margin-left: -6px; margin-right: -6px; top: 10px;" v-loading="readLoading">
            <div class="el-col el-col-12" style="padding-left: 6px; padding-right: 6px;">
                <div class="el-card is-hover-shadow" style="height: 750px;"><!---->
                    <div class="el-card__header">
                        <div class="clearfix">
                            <span>模版代码</span>
                        </div>
                    </div>
                    <div class="el-card__body">
                        <el-input type="textarea" :rows="29" v-model="form.templateContent"></el-input>
                    </div>
                </div>
            </div>
            <div class="el-col el-col-12" style="padding-left: 6px; padding-right: 6px;">
                <div class="el-card is-hover-shadow" style="height: 750px;"><!---->
                    <div class="el-card__header">
                        <div class="clearfix">
                            <span>模版展示</span>
                        </div>
                    </div>
                    <div class="el-card__body">
                        <v-runtime-template :template="template"></v-runtime-template>
                    </div>
                </div>
            </div>
        </div>
        <!--<el-row :gutter="12" style="top:10px;">-->
            <!--<el-col :span="12">-->
                <!--<el-card shadow="hover" style="height: 650px;">-->
                    <!--<div slot="header" class="clearfix">-->
                        <!--<span>模版代码</span>-->
                    <!--</div>-->
                <!--</el-card>-->
            <!--</el-col>-->
            <!--<el-col :span="12">-->
                <!--<el-card shadow="hover" style="height: 650px;">-->
                    <!--<div slot="header" class="clearfix">-->
                        <!--<span>模版展示</span>-->
                    <!--</div>-->
                <!--</el-card>-->
            <!--</el-col>-->
        <!--</el-row>-->
        <el-dialog
                :visible.sync="dialogVisible"
                width="698px"
                top="6vh"
                :before-close="closePreviewDialog">
            <template slot="title">
                <title-icon/>XXX设备
            </template>
            <preview-details :dialogVisible="dialogVisible" :template="previewTemplateContent"></preview-details>
            <div slot="footer">
                <el-button type="primary" @click="closePreviewDialog">{{$t('commons.close')}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import VRuntimeTemplate from "v-runtime-template";
    // 导入主题，引用时在v-chart中增加theme="macarons"
    import 'echarts/theme/macarons'
    import {
        findDeviceDetailsTemplateByDeviceType,
        saveDeviceDetailsTemplate
    } from '@/api/ruge/iot/map/DFTemplate'
    import { findLookupItem } from '@/api/ruge/lookup/lookup'
    import PreviewDetails from "./preview";

    export default {
        name: "detailsManager",
        components: {
            PreviewDetails,
            VRuntimeTemplate,
        },
        data(){
            return{
                dialogVisible:false,
                readLoading:false,
                form :{
                  deviceType : null,
                  templateContent :null,
                },
                deviceTypeList:[],
                template: ``,
                previewTemplateContent:``,
                //<vue-drag-resize :isResizable="true" :title="'点位1'" :parentLimitation="true" :w="200" :h="175" :x="0" :y="0" ref="blockSon">
            }
        },
        created(){
            this.getDeviceTypeList();
            this.form.templateContent = this.initTemplate(this.template);
        },
        methods:{
            //预览模版
            previewTemplate(){
                this.previewTemplateContent = this.initTemplate(this.form.templateContent);
                this.dialogVisible = true;
            },
            //关闭预览模版弹窗
            closePreviewDialog(){
                this.previewTemplateContent = ``;
                this.dialogVisible = false;
            },
            //初始化模版格式
            initTemplate(template){
                let templateContent = `<div style="width: 600px;height: 650px;">`+template+`</div>`;
                this.template = templateContent;
                return template;
            },
            //运行模版
            runTemplate(){
                this.initTemplate(this.form.templateContent);
            },
            //加载设备类型列表
            getDeviceTypeList(){
                findLookupItem('DEVICE_TYPE').then((row)=> {
                    this.deviceTypeList = row;
                })
            },
            //加载设备类型详情模版
            getDeviceDetailsTemplateByDeviceType(){
                this.readLoading = true;
                findDeviceDetailsTemplateByDeviceType({deviceType: this.form.deviceType}).then((row)=> {
                    if(row){
                        this.form.templateContent = this.initTemplate(row.templateContent);
                    }else{
                        this.form.templateContent = this.initTemplate(``);
                    }
                }).finally(()=>{
                    this.readLoading = false;
                })
            },
            //保存地图详情模版
            saveMapDetailsTemplate(){
                saveDeviceDetailsTemplate(this.form).then(() => {
                    this.$message({
                        type: 'success',
                        message: this.$t('message.saveSuccess')
                    });
                }).catch(error => {
                    console.log(`保存失败，原因 => ${error}`)
                })
            },
            //构造一个仪表盘
            buildDashboardOptions(pointName,symbol,value){
                return {
                    tooltip : {
                        formatter: "{a} <br/>{b} : {c}V"
                    },
                    series: [
                        {
                            name: pointName,
                            type: 'gauge',
                            center: ['60%', '50%'],
                            min: 0,
                            max: 300,
                            splitNumber: 4,
                            radius: '80%',
                            axisLine: { // 坐标轴线
                                lineStyle: { // 属性lineStyle控制线条样式
                                    color: [
                                        [0.2, '#e4ac55'],
                                        [0.8, '#42c68c'],
                                        [1, '#e35151']
                                    ],
                                    width: 10,
                                    shadowColor: '#fff', //默认透明
                                    shadowBlur: 10
                                }
                            },
                            axisLabel: { // 坐标轴小标记
                                textStyle: {
                                    color: '#666',
                                    fontSize:10,
                                    shadowColor: '#fff', //默认透明
                                    shadowBlur: 10
                                }
                            },
                            splitLine: { // 分隔线
                                length:8, // 属性length控制线长
                                lineStyle: {
                                    width: 1,
                                    color: '#fff',
                                    shadowColor: '#fff', //默认透明
                                    shadowBlur: 10
                                }
                            },
//					指针
                            pointer: {
                                width: 2,
                                shadowColor: '#e7a954', //默认透明
                                shadowBlur: 5
                            },
                            detail: {
                                fontSize:14,
                                color:"#333",
                                offsetCenter:[0,'90%'],
                                formatter:'{value}'+symbol
                            },
//					数据
                            data: [{
                                value: value,
                                name: symbol
                            }]
                        }
                    ]
                };
            },
        }
    }
</script>
